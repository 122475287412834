#locations {

   .card {

    background-color: black;

  }
}

.actions {


  margin: 0 auto;

  display: inline;
}